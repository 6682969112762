import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

// elements

import LanguageToggle from '~/components/LanguageToggle'
import PageTitle from '~/components/PageTitle'
import { useAppInfo } from '~/hooks/useAppInfo'

import LoginForm from '../../components/LoginForm'

import * as S from './styles'

const Login = () => {
  const { t } = useTranslation()
  /**
   * Handle app info
   */
  const { logo, logoWhiteLabel, name } = useAppInfo()

  const urlLogo = logoWhiteLabel || logo
  /**
   * END = Handle app info
   */

  const { search } = useLocation()
  // const params = new URLSearchParams(search)

  // useEffect(() => {
  //   if (params?.get('revalidate') === 'token') {
  //     params.delete('revalidate')
  //     history.push({ search: params.toString() })
  //   }
  // }, [params])

  const hasPath = search.indexOf('callback_url=/shopping-carts/review-bar/')

  return (
    <>
      {hasPath < 0 ? (
        <>
          <PageTitle title="Login" />

          <S.Container className="m-auto px-5 py-4 bg-white border rounded-3">
            <header className="text-center">
              {urlLogo && (
                <img
                  src={urlLogo}
                  alt={name}
                  className="mb-1 object-fit-contain"
                  width="228"
                  height="89"
                />
              )}

              <p>
                {t('auth:login.welcome.message', {
                  accountName: name || 'Newtail'
                })}
              </p>
            </header>

            <LoginForm />

            <footer>
              <LanguageToggle />
            </footer>
          </S.Container>

          {/* <div className="login"> */}
          {/* <LoginForm /> */}
          {/* <LoginSlider /> */}
          {/* </div> */}
        </>
      ) : (
        <S.ContainerReviewCart>
          <span>
            Para revisar o carrinho você precisa efetuar login na plataforma
            Newtail.{' '}
            <Link to="/login" target="_blank">
              Faça o login
            </Link>{' '}
            e atualize essa página.
          </span>
        </S.ContainerReviewCart>
      )}
    </>
  )
}

export default Login
