import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { t } from 'i18next'

import { Filters } from '~/components/Filters'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import { useAppInfo } from '~/hooks/useAppInfo'
import { useAppSelector } from '~/store/hooks'

import RetailMediaExternalReport from '../../components/Report'
import { advertiserPublisherFilters } from '../../store/common/filters/advertiserPublisher'

const RetailMediaReport = () => {
  const { publisher, advertiser } = useAppSelector(
    state => state.filters.retailMedia
  )

  const { accountType } = useAppSelector(state => state.signin)

  // const customFilters = useMemo(
  //   () => ({
  //     advertiser_id: advertiser?.value,
  //     publisher_id: publisher?.value
  //   }),
  //   [publisher, advertiser]
  // )

  const headerFilters = useMemo(
    () => ({
      genericFilters: [advertiserPublisherFilters[accountType]]
    }),
    [accountType]
  )

  const { isWhiteLabel } = useAppInfo()

  /**
   * Params
   */
  type ExternalDashboardKeys = 'ads' | 'overview'

  const location = useLocation()

  const getKeyFromURL = useMemo(() => {
    const path = location.pathname
    const parts = path.split('/')

    return parts[parts.length - 1] as ExternalDashboardKeys
  }, [location.pathname])

  type DashboardNames = {
    [key in ExternalDashboardKeys]: ExternalDashboardNames
  }

  type DashboardTitles = {
    [key in ExternalDashboardKeys]: string
  }

  const dashboardNames: DashboardNames = {
    ads: 'campaign_resume',
    overview: 'dashboard_geral'
  }

  const dashboardTitles: DashboardTitles = {
    ads: t('rm:Ads'),
    overview: 'Gerencial'
  }

  const customFilters = useMemo(
    () => ({
      ads: {
        advertiser_id: advertiser?.value,
        publisher_id: publisher?.value
      },
      overview: null
    }),
    [publisher, advertiser]
  )

  return (
    <>
      <PageTitle
        title={`Relatório - ${dashboardTitles?.[getKeyFromURL]} - Retail media`}
      />

      <HeaderPages
        title={`Retail media - Relatórios > ${dashboardTitles?.[getKeyFromURL]}`}
        injectDatepicker
        className="has-datepicker"
      />

      {!isWhiteLabel && customFilters?.[getKeyFromURL] && (
        <Filters {...headerFilters} />
      )}

      <RetailMediaExternalReport
        useDate
        customFilters={customFilters?.[getKeyFromURL]}
        dashboardName={dashboardNames?.[getKeyFromURL]}
      />
    </>
  )
}

export default RetailMediaReport
